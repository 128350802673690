import { useEffect } from "react";
import { Link } from "react-router-dom";
import { initDemo2 } from "./index2";
import {motion} from "framer-motion";
import {Grid} from "../../Components/3d_imageEffects/grid"

function Demo2() {

    useEffect(() => {
        initDemo2();
        const grid = new Grid(document.querySelector(".gallery"));
    }, []);

    const componentVariants = {
        hidden: {
            opacity: 0,
            y: "100vh"
        },
        visible: {
            opacity: 1,
            x: 0,
            y: 0,
            transition: { delay: 0.2, duration: 1.2 }
        },
        exit:{
            opacity: 0,
            transition:{ease:"easeInOut"}
        }
    }

    return (
        <motion.div className="demo2" variants={componentVariants} animate="visible" initial="hidden" exit="exit">
            <div className="frame">
                <h1 className="frame__title">
                    Horizontal Smooth Scroll Layout <br /> using{" "}
                    <a href="https://locomotivemtl.github.io/locomotive-scroll/">
                        Locomotive Scroll
                    </a>
                </h1>
                <div className="frame__demos">
                    <Link className="frame__demo" to="/index.html">
                        Demo__01
                    </Link>
                    <Link className="frame__demo frame__demo--current" to="/index2.html">
                        Demo__02
                    </Link>
                    <Link className="frame__demo" to="/index3.html">
                        Demo__03
                    </Link>
                    <Link className="frame__demo" to="/index4.html">
                        Demo__04
                    </Link>
                    <Link className="frame__demo" to="/index5.html">
                        Demo__05
                    </Link>
                </div>
                <div className="frame__links">
                    <a href="https://tympanus.net/Development/ImageStackGrid/">
                        Previous demo
                    </a>
                    <a href="https://tympanus.net/codrops/?p=52157">Article</a>
                    <a href="https://github.com/codrops/HorizontalSmoothScrollLayout/">
                        GitHub
                    </a>
                </div>
            </div>
            <div className="rotate">
                <main data-scroll-container="">
                    <div className="content">
                        <div className="gallery">
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/1.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Moonraker
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        01
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#house</span>
                                        <span>#green</span>
                                        <span>#chair</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/2.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Nacarat
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        02
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#love</span>
                                        <span>#hug</span>
                                        <span>#people</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/3.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Selkie
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        03
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#hike</span>
                                        <span>#nature</span>
                                        <span>#rain</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/4.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Thalassic
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        04
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#free</span>
                                        <span>#wood</span>
                                        <span>#fire</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <div className="gallery__text">
                                <span
                                    className="gallery__text-inner"
                                    data-scroll=""
                                    data-scroll-speed={1}
                                >
                                    Verjuice
                                </span>
                                <span
                                    data-scroll=""
                                    data-scroll-speed={3}
                                    className="gallery__text-inner"
                                >
                                    Zyrian
                                </span>
                            </div>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/5.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Uroboros
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        05
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#cloud</span>
                                        <span>#lake</span>
                                        <span>#frog</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/6.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Waitron
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        06
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#tent</span>
                                        <span>#flower</span>
                                        <span>#love</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/7.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Soucouyant
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        07
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#water</span>
                                        <span>#bottle</span>
                                        <span>#hand</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/8.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Periapt
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        08
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#night</span>
                                        <span>#stars</span>
                                        <span>#moon</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <div className="gallery__text">
                                <span
                                    className="gallery__text-inner"
                                    data-scroll=""
                                    data-scroll-speed={1}
                                >
                                    Tiffin
                                </span>
                                <span
                                    data-scroll=""
                                    data-scroll-speed={2}
                                    className="gallery__text-inner"
                                >
                                    Skanky
                                </span>
                            </div>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/9.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Cyanic
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        09
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#sun</span>
                                        <span>#light</span>
                                        <span>#air</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/10.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Martlet
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        10
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#vital</span>
                                        <span>#fog</span>
                                        <span>#close</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/11.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Eurhythmic
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        11
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#cover</span>
                                        <span>#bed</span>
                                        <span>#window</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure className="gallery__item">
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        style={{ backgroundImage: "url(img/demo2/12.jpg)" }}
                                        data-scroll=""
                                        data-scroll-speed="-0.8"
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Dariole
                                    </h2>
                                    <span
                                        className="gallery__item-number"
                                        data-scroll=""
                                        data-scroll-speed="1.5"
                                    >
                                        12
                                    </span>
                                    <p className="gallery__item-tags">
                                        <span>#sad</span>
                                        <span>#mouth</span>
                                        <span>#tear</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <div className="gallery__text">
                                <span
                                    className="gallery__text-inner"
                                    data-scroll=""
                                    data-scroll-speed={3}
                                >
                                    Deasil
                                </span>
                                <span
                                    data-scroll=""
                                    data-scroll-speed={1}
                                    className="gallery__text-inner"
                                >
                                    Zetetic
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <svg className="cursor" width={20} height={20} viewBox="0 0 20 20">
                <circle className="cursor__inner" cx={10} cy={10} r={5} />
            </svg>
        </motion.div>
    );
}

export default Demo2;