

import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation } from "react-router-dom";

// Components
import Demo1 from "./Demos/Demo1/Demo1.js";
import Demo2 from "./Demos/Demo2/Demo2.js";
import Demo3 from "./Demos/Demo3/Demo3.js";
import Demo4 from "./Demos/Demo4/Demo4.js";
import Demo5 from "./Demos/Demo5/Demo5.js";
import NotFound from "./NotFound.js";

function App() {

  const location = useLocation();

  return (
    <AnimatePresence mode="wait" presenceAffectsLayout="false">
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Demo1 />} />
        <Route path="/index.html" element={<Demo1 />} />
        <Route path="/index2.html" element={<Demo2 />} />
        <Route path="/index3.html" element={<Demo3 />} />
        <Route path="/index4.html" element={<Demo4 />} />
        <Route path="/index5.html" element={<Demo5 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
