import { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {
    useEffect(()=>document.body.classList.remove('loading'),[]);
    return (
        <div className="container">
            <h1>404 :(
                <br />
                Page not found!
                Visit <u><Link to="/">here</Link></u>
            </h1>
        </div>
     );
}

export default NotFound;