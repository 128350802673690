import { useEffect } from "react";
import { Link } from "react-router-dom";
import { initDemo5 } from "./index5";
import {motion} from "framer-motion";
import { Grid } from "../../Components/3d_imageEffects/grid";

function Demo5() {

    useEffect(() => {
        initDemo5();
        const grid = new Grid(document.querySelector(".gallery"))
    }, []);

    const componentVariants = {
        hidden: {
            opacity: 0,
            transform: "scaleY(-1)",
        },
        visible: {
            opacity: 1,
            transform: "scaleY(1)",
            transition: { delay: 0.2, duration: 1.2 }
        },
        exit:{
            // transition:{ease:"easeInOut"},
            transition: { duration: 1.2 },
            opacity: 0,
            transform: "scaleX(-2)",
        }
    }

    return (
        <motion.div className="demo5" variants={componentVariants} animate="visible" initial="hidden" exit="exit">
            <>
                <div className="frame">
                    {/* don't put fixed elements into the scroll container! */}
                    <h1 className="frame__title">
                        Horizontal Smooth Scroll Layout <br /> using
                        <a href="https://locomotivemtl.github.io/locomotive-scroll/">
                            Locomotive Scroll
                        </a>
                    </h1>
                    <div className="frame__demos">
                        <Link className="frame__demo" to="/index.html">
                            Demo__01
                        </Link>
                        <Link className="frame__demo" to="/index2.html">
                            Demo__02
                        </Link>
                        <Link className="frame__demo" to="/index3.html">
                            Demo__03
                        </Link>
                        <Link className="frame__demo" to="/index4.html">
                            Demo__04
                        </Link>
                        <Link className="frame__demo frame__demo--current" to="/index5.html">
                            Demo__05
                        </Link>
                    </div>
                    <div className="frame__links">
                        <a href="https://tympanus.net/Development/ImageStackGrid/">
                            Previous demo
                        </a>
                        <a href="https://tympanus.net/codrops/?p=52157">Article</a>
                        <a href="https://github.com/codrops/HorizontalSmoothScrollLayout/">
                            GitHub
                        </a>
                    </div>
                </div>
                <main data-scroll-container="">
                    <div className="content">
                        <div className="gallery">
                            <div className="gallery__text">
                                <span
                                    className="gallery__text-inner"
                                    data-scroll=""
                                    data-scroll-speed={3}
                                    data-scroll-direction="vertical"
                                >
                                    Ariel
                                </span>
                                <span
                                    data-scroll=""
                                    data-scroll-speed={-4}
                                    data-scroll-direction="vertical"
                                    className="gallery__text-inner"
                                >
                                    Croze
                                </span>
                            </div>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/3.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Funambulist
                                    </h2>
                                    <span className="gallery__item-number">01</span>
                                    <p className="gallery__item-tags">
                                        <span>#house</span>
                                        <span>#green</span>
                                        <span>#chair</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={-2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/2.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Omophagy
                                    </h2>
                                    <span className="gallery__item-number">02</span>
                                    <p className="gallery__item-tags">
                                        <span>#love</span>
                                        <span>#hug</span>
                                        <span>#people</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/1.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Conniption
                                    </h2>
                                    <span className="gallery__item-number">03</span>
                                    <p className="gallery__item-tags">
                                        <span>#hike</span>
                                        <span>#nature</span>
                                        <span>#rain</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={-2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/6.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Xenology
                                    </h2>
                                    <span className="gallery__item-number">04</span>
                                    <p className="gallery__item-tags">
                                        <span>#free</span>
                                        <span>#wood</span>
                                        <span>#fire</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/4.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Lycanthropy
                                    </h2>
                                    <span className="gallery__item-number">05</span>
                                    <p className="gallery__item-tags">
                                        <span>#cloud</span>
                                        <span>#lake</span>
                                        <span>#frog</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={-2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/5.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Mudlark
                                    </h2>
                                    <span className="gallery__item-number">06</span>
                                    <p className="gallery__item-tags">
                                        <span>#tent</span>
                                        <span>#flower</span>
                                        <span>#love</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/12.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Illywhacker
                                    </h2>
                                    <span className="gallery__item-number">07</span>
                                    <p className="gallery__item-tags">
                                        <span>#water</span>
                                        <span>#bottle</span>
                                        <span>#hand</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={-2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/8.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Disenthral
                                    </h2>
                                    <span className="gallery__item-number">08</span>
                                    <p className="gallery__item-tags">
                                        <span>#night</span>
                                        <span>#stars</span>
                                        <span>#moon</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/7.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Abaya
                                    </h2>
                                    <span className="gallery__item-number">09</span>
                                    <p className="gallery__item-tags">
                                        <span>#sun</span>
                                        <span>#light</span>
                                        <span>#air</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={-2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/11.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Hallux
                                    </h2>
                                    <span className="gallery__item-number">10</span>
                                    <p className="gallery__item-tags">
                                        <span>#vital</span>
                                        <span>#fog</span>
                                        <span>#close</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/10.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Lablab
                                    </h2>
                                    <span className="gallery__item-number">11</span>
                                    <p className="gallery__item-tags">
                                        <span>#cover</span>
                                        <span>#bed</span>
                                        <span>#window</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <figure
                                className="gallery__item"
                                data-scroll=""
                                data-scroll-speed={-2}
                                data-scroll-direction="vertical"
                            >
                                <div className="gallery__item-img">
                                    <div
                                        className="gallery__item-imginner"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                        data-scroll-direction="vertical"
                                        style={{ backgroundImage: "url(img/demo1/9.jpg)" }}
                                    />
                                </div>
                                <figcaption className="gallery__item-caption">
                                    <h2
                                        className="gallery__item-title"
                                        data-scroll=""
                                        data-scroll-speed={1}
                                    >
                                        Momisom
                                    </h2>
                                    <span className="gallery__item-number">12</span>
                                    <p className="gallery__item-tags">
                                        <span>#sad</span>
                                        <span>#mouth</span>
                                        <span>#tear</span>
                                    </p>
                                    <a className="gallery__item-link">explore</a>
                                </figcaption>
                            </figure>
                            <div className="gallery__text">
                                <span
                                    className="gallery__text-inner"
                                    data-scroll=""
                                    data-scroll-speed={-4}
                                    data-scroll-direction="vertical"
                                >
                                    Daria
                                </span>
                                <span
                                    data-scroll=""
                                    data-scroll-speed={3}
                                    data-scroll-direction="vertical"
                                    className="gallery__text-inner"
                                >
                                    Gaita
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
                <svg className="cursor" width={20} height={20} viewBox="0 0 20 20">
                    <circle className="cursor__inner" cx={10} cy={10} r={5} />
                </svg>
            </>

        </motion.div>
    );
}

export default Demo5;